import { ResultItem, Results } from "@/store/modules/filterStore/types";
import { union } from "lodash";

const getResultGroupsUnion = (resultGroups: Results[]) => {
    let results: ResultItem[] = [];

    resultGroups.sort((groupA, groupB) => {
        return groupA.length > groupB.length ? -1 : 1;
    });

    resultGroups.forEach((resultGroup) => {
        if (!resultGroup.length) return;

        if (!results.length) {
            results = [...resultGroup];
        } else {
            results = union(results, resultGroup);
        }
    });

    return results;
};

export default getResultGroupsUnion;

import { ResultGroups, Results } from "./types";
import { FILTER_MODES } from "./constants";

export interface FilterState {
    searchTerm: string;
    filterInitialized: boolean;
    filterTeaserCounts: number;
    filterTeasersLoaded: boolean;
    loadedFilterTeasers: number;
    availableResults: Results;
    availableResultsCount: number;
    filteredResults: any[];
    filteredResultsCount: number;
    filteredGroupResults: Record<string, any>;
    totalResultGroups: ResultGroups;
    availableResultGroups: ResultGroups;
    availableFilterGroups: Record<string, any>;
    sortedFilterGroups: Record<string, any>;
    activeFilters: any[];
    loading: boolean;
    mobileFiltersOpen: boolean;
    mobileFiltersOpenedWithGroup: string;
    mobileFiltersGroupScrollPositions: Record<string, number>;
    orderValue: number;
    sortingValue: number;
    SORTING_MAP: Record<number, string>;
    selectedRecordingFilters: any[];
    filterMode: string;
}

export const state: FilterState = {
    searchTerm: "",
    filterInitialized: false,
    filterTeaserCounts: 0,
    filterTeasersLoaded: false,
    loadedFilterTeasers: 0,
    availableResults: [],
    availableResultsCount: 0,
    filteredResults: [],
    filteredGroupResults: {},
    filteredResultsCount: 0,
    totalResultGroups: {},
    availableResultGroups: {},
    availableFilterGroups: {},
    sortedFilterGroups: {},
    activeFilters: [],
    loading: true,
    mobileFiltersOpen: false,
    mobileFiltersOpenedWithGroup: "",
    mobileFiltersGroupScrollPositions: {},
    orderValue: 1,
    sortingValue: 0,
    SORTING_MAP: {
        0: "filterResults",
        1: "title",
        2: "timestamp",
        3: "popularity",
    },
    selectedRecordingFilters: [],
    filterMode: FILTER_MODES.AND,
};

import { Module } from "vuex";

import { state, FilterState } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const namespaced = true;

export const filterStore: Module<FilterState, any> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};

import { MutationTree } from "vuex";

import { MobileFilterScrollPositions, Results } from "./types";
import { FilterSections } from "../../../components/organisms/filter-multi/types";
import { FilterState } from "./state";

export const mutations: MutationTree<FilterState> = {
    SET_FILTER_INITIALIZED(state: FilterState, isInitialized: boolean) {
        state.filterInitialized = isInitialized;
    },

    SET_SEARCH_TERM(state: FilterState, searchTerm: string): void {
        state.searchTerm = searchTerm;
    },

    SET_FILTER_TEASERS_LOADED(state: FilterState, loaded: boolean) {
        state.filterTeasersLoaded = loaded;
    },

    SET_FILTER_TEASER_COUNT(state: FilterState, count: number) {
        state.filterTeaserCounts = count;
    },

    SET_LOADED_FILTER_TEASERS(state: FilterState, count: number) {
        state.loadedFilterTeasers = count;
    },

    SET_AVAILABLE_RESULTS(state: FilterState, results: Results) {
        state.availableResults = results;
    },

    SET_FILTERED_RESULTS(state: FilterState, results: Results) {
        state.filteredResults = results;
    },

    SET_AVAILABLE_FILTER_GROUPS(state: FilterState, filterGroups: FilterSections) {
        state.availableFilterGroups = filterGroups;
    },

    SET_ACTIVE_FILTERS(state: FilterState, searchTerms: string[]) {
        state.activeFilters = searchTerms;
    },

    SET_TOTAL_RESULT_GROUPS(state: FilterState, totalResultGroups: Record<string, Results>) {
        state.totalResultGroups = totalResultGroups;
    },

    SET_FILTERED_GROUP_RESULTS(state: FilterState, filteredGroupResults: Record<string, Results>) {
        state.filteredGroupResults = filteredGroupResults;
    },

    SET_AVAILABLE_RESULT_GROUPS(state: FilterState, availableResultGroups: Record<string, Results>) {
        state.availableResultGroups = availableResultGroups;
    },

    SET_LOADING(state: FilterState, loading: boolean) {
        state.loading = loading;
    },

    SET_MOBILE_FILTERS_OPEN(state: FilterState, isOpen: boolean) {
        state.mobileFiltersOpen = isOpen;
    },

    SET_MOBILE_FILTERS_OPEN_WITH_GROUP(state: FilterState, filterGroup: string) {
        state.mobileFiltersOpenedWithGroup = filterGroup;
    },

    SET_MOBILE_FILTERS_GROUP_SCROLL_POSITIONS(state: FilterState, scrollPositions: MobileFilterScrollPositions) {
        state.mobileFiltersGroupScrollPositions = scrollPositions;
    },

    SET_SORTING_VALUE(state: FilterState, sortingValue: number) {
        state.sortingValue = sortingValue;
    },

    SET_ORDER_VALUE(state: FilterState, orderValue: number) {
        state.orderValue = orderValue;
    },

    SET_FILTER_MODE(state: FilterState, filterMode: string) {
        state.filterMode = filterMode;
    },

    SET_RECORDING_FILTERS: function (state: FilterState, filterTopic: string) {
        const currentFilters = Object.assign([], state.selectedRecordingFilters);

        if (currentFilters.includes(filterTopic)) {
            currentFilters.splice(currentFilters.indexOf(filterTopic), 1);
        } else {
            currentFilters.push(filterTopic);
        }

        state.selectedRecordingFilters = currentFilters;
    },

    CLEAR_RECORDING_FILTERS: function (state: FilterState, filters: string[]) {
        state.selectedRecordingFilters = filters;
    },
};

import { Results } from "@/store/modules/filterStore/types";

const andFilter = (results: Results, activeFilters: string[]) => {
    if (!activeFilters.length) return results;

    return results.filter((result) => {
        const resultHasTerm = (term: string) => result.filterterms.indexOf(term) > -1;

        return activeFilters.every(resultHasTerm);
    });
};

export default andFilter;
